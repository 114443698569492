/**
 * 判断当前运行环境
 * @returns {Object} 包含环境信息的对象
 */
export const getEnvironment = () => {
  // 判断是否在Electron环境中
  const isElectron = process.env.IS_ELECTRON;
  
  // 判断是否在开发环境
  const isDevelopment = process.env.NODE_ENV === 'development';
  
  // 判断是否在生产环境
  const isProduction = process.env.NODE_ENV === 'production';

  return {
    isElectron,
    isDevelopment,
    isProduction,
    // 是否在浏览器中运行
    isBrowser: !isElectron,
    // 运行环境描述
    environment: isElectron ? 'electron' : 'browser'
  };
};

export default {
  getEnvironment
}; 
// utils/axiosInstance.js
import axios from 'axios'
import router from '../router'

let url = `https://yckanban.jackafan.top/`;
if(process.env.NODE_ENV=="development"){
  url = `http://${window.location.hostname}:3000/`;
}else if (process.env.NODE_ENV == "production") {
  //生产环境
}else{
  //本地环境
}

const instance = axios.create({
  baseURL: url,
  withCredentials: true // 添加withCredentials选项
});

// 添加请求拦截器
instance.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 添加响应拦截器
instance.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  return response;
}, function (error) {
  // 对响应错误做点什么
  if (error.response && error.response.status === 401) {
    // 如果响应的状态码是401，将用户重定向到登录页面
    router.push('/login');
  }
  return Promise.reject(error);
});

// 添加electron环境判断
const isElectron = process.env.IS_ELECTRON

if (isElectron) {
  if (process.env.NODE_ENV == "development") {
    instance.defaults.baseURL = 'http://localhost:3000';
  } else if (process.env.NODE_ENV == "production") {
    instance.defaults.baseURL = 'https://yckb.jackafan.top';
  }
}

export default instance;
